/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.Menu {
    &-SubCategoriesWrapper {
        top: calc(var(--header-nav-height) + 12.5px);
        // z-index:100;
        overflow:hidden;
    }

    &-SubItemWrapper {
        flex-basis: 50px;
        @include desktop {
            margin-right: 2.4rem;
        }
    }

    &-SubCategories {
        overflow: hidden;
        padding: 20px 55px 0 1.2rem;
    }
}

.Menu-Promotion{
    display: none;
    padding: 0 1.2rem 10px;
}

.Menu-SubCategories .Menu-SubItemWrapper figure.Menu-ItemFigure {
    height: unset;
}

.Menu-SubItemWrapper>.Menu-Link {
    @include desktop {
        min-height: 20px;
    }
}
