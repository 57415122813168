/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

figure{
    height: 50vh;
    max-height: 600px;
    margin-bottom: 1em !important;
}
.Image_ratio_square{
    height:100%;
    padding-bottom: 1em;
}

.pagebuilder-mobile-only{
    display: none;
}

.pagebuilder-mobile-only img, :root div.pagebuilder-mobile-hidden img.Image-Image{
    width:100% !important;
    object-position: center top;
    object-fit: cover !important;
}

.ProductCard-Figure{
    height: inherit;
}

@media screen and (max-width: 769px) {
    .pagebuilder-mobile-hidden{
        display: none;
    }
    .banner-only-web{
        display: none !important;
    }
    figure{
        height: 50vh;
        margin-bottom: 1em !important;
    }
    .pagebuilder-mobile-only{
        display: block;
    }
}